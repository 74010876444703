var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"contactFormContainer",staticClass:"pa-0 pt-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.name'),"rules":_vm.rules.nome},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.nome),callback:function ($$v) {_vm.$set(_vm.model, "nome", $$v)},expression:"model.nome"}})]}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.contactType'),"rules":_vm.rules.tiposContato,"required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.contactTypeOptions,"rules":rules,"label":label,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"auto","multiple":"","clearable":"","small-chips":""},model:{value:(_vm.model.tiposContato),callback:function ($$v) {_vm.$set(_vm.model, "tiposContato", $$v)},expression:"model.tiposContato"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.email'),"rules":_vm.rules.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.telephone'),"rules":_vm.rules.telefone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMaskActive && _vm.telephoneMask),expression:"telephoneMaskActive && telephoneMask"}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"focus":function($event){_vm.telephoneMaskActive = true}},model:{value:(_vm.model.telefone),callback:function ($$v) {_vm.$set(_vm.model, "telefone", $$v)},expression:"model.telefone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.whatsapp'),"rules":_vm.rules.whatsapp},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.whatsappMaskActive && _vm.whatsappMask),expression:"whatsappMaskActive && whatsappMask"}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"focus":function($event){_vm.whatsappMaskActive = true}},model:{value:(_vm.model.whatsapp),callback:function ($$v) {_vm.$set(_vm.model, "whatsapp", $$v)},expression:"model.whatsapp"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.contactForm.birthdate'),"rules":_vm.rules.dataAniversario},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('DatePickerField',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.dataAniversario),callback:function ($$v) {_vm.$set(_vm.model, "dataAniversario", $$v)},expression:"model.dataAniversario"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('crm.contactForm.active'),"value":0,"hide-details":"auto"},model:{value:(_vm.model.flagAtivo),callback:function ($$v) {_vm.$set(_vm.model, "flagAtivo", $$v)},expression:"model.flagAtivo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onSave}},[_c('span',[_vm._v(_vm._s(_vm.saveButtonText))])])],1),(_vm.showSync)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"lightgrey","disabled":_vm.disableSync},on:{"click":_vm.onSync}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.syncToErp')))])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }